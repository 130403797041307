const navbarEffects = function navbarEffects() {
  const navbar = document.querySelectorAll(".navbar:not(.always-shown)")[0] || null;
  const initPos = window.scrollY;
  let prevScrollPos = initPos;
  let curScrollPos = initPos;

  const directionDetector = function directionDetector(prevScrollPos, curScrollPos) {
    if (window.scrollY > 0) {
      if (prevScrollPos >= curScrollPos) {
        if (curScrollPos != 0) {
          navbar.classList.add("showed");
          navbar.classList.remove("hidden");
        } else {
          navbar.classList.remove("hidden");
          navbar.classList.remove("showed");
        }
      } else {
        navbar.classList.add("hidden");
        navbar.classList.remove("showed");
      }
    } else {
      navbar.classList.remove("hidden");
      navbar.classList.remove("showed");
    }
  };

  if (navbar) {
    if (!document.querySelectorAll(".menu.open").length) directionDetector(window.scrollY, window.scrollY);
    window.addEventListener("scroll", function () {
      prevScrollPos = curScrollPos;
      curScrollPos = window.scrollY;
      if (!document.querySelectorAll(".menu.open").length) directionDetector(prevScrollPos, curScrollPos);
    });
  }
}


const menuHandler = function menuHandler() {
  const menuTogglers = document.querySelectorAll(".menu-trigger");
  const menu = document.querySelectorAll(".menu")[0] || null;

  if (menu) {
    menuTogglers.forEach(function (toggler) {
      toggler.addEventListener("click", function () {
        menu.classList.toggle("open");
        curScrollPos = window.scrollY;
        let navbar = document.querySelectorAll(".navbar")[0];
        navbar.classList.toggle("menu-open");
        if (curScrollPos != 0) {
          navbar.classList.add("showed");
          navbar.classList.remove("hidden");
        } else {
          navbar.classList.remove("hidden");
          navbar.classList.remove("showed");
        }
      });
    });
  }
};


const serviceMenuHandler = function serviceMenuHandler() {
  const accordionMenuContainer = document.querySelector(".with-accordion-menu");
  const accordionMenuToggle = document.querySelector(".accordion-toggle");
  const accordionMenu = document.querySelectorAll(".accordion-menu");

  const handleAccordingMenuClickOutside = function handleAccordingMenuClickOutside(e) {
    if (!accordionMenuContainer.contains(e.target)) {
      accordionMenuContainer.classList.remove('active');
      document.removeEventListener("mousedown", handleAccordingMenuClickOutside);
    }
  }

  const handleAccordingMenuClickToggle = function handleAccordingMenuClickToggle() {
    if (accordionMenuContainer.classList.contains('active')) {
      accordionMenuContainer.classList.remove('active');
      document.removeEventListener("mousedown", handleAccordingMenuClickOutside);
    } else {
      accordionMenuContainer.classList.add('active');
      document.addEventListener("mousedown", handleAccordingMenuClickOutside);
    }
  }

  accordionMenuToggle.addEventListener("click", handleAccordingMenuClickToggle);
  accordionMenu.forEach(function (menu) {
    const navs = menu.querySelectorAll('.accordion-nav button');
    const tabs = menu.querySelectorAll('.accordion-tabs li:not(.mobile-nav)');
    const mobile_navs = menu.querySelectorAll('.accordion-tabs li.mobile-nav');
    for (const nav of navs) {
      nav.addEventListener("mouseover", function (e) {
        let j = 0;
        for (const i of navs) {
          i.classList.remove("active");
          if (i == e.currentTarget) {
            for (const t of tabs) {
              t.classList.remove("active");
            }
            tabs[j].classList.add("active");
          }
          j++;
        }
        e.currentTarget.classList.add("active");
      });
    };
    for (const nav of mobile_navs) {
      nav.addEventListener("click", function (e) {
        let j = 0;
        for (const i of mobile_navs) {
          if (i == e.currentTarget) {
            for (const t of tabs) {
              t.classList.remove("active");
            }
            if (i.classList.contains("active")) {
              i.classList.remove("active");
            } else {
              i.classList.add("active");
              tabs[j].classList.add("active");
            }
          } else {
            i.classList.remove("active");
          }
          j++;
        }
      });
    };
  });
};

window.addEventListener('DOMContentLoaded', function () {
  navbarEffects();
  menuHandler();
  serviceMenuHandler();
}, { once: true });